import { parse } from "yaml"
import { createI18n } from "vue-i18n"

const messages: any = {}

Object.entries(
  import.meta.glob("./locales/*.yml", {
    eager: true,
    as: "raw",
  }),
).forEach((module) => {
  const path = module[0]
  const locale = path.slice(10, 12)
  messages[locale] = parse(module[1])
})

const i18n = createI18n({
  legacy: false,
  locale: "de",
  messages,
})

export default i18n
