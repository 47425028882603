<template>
  <Default :show-locale-switcher="false">
    <div
      class="absolute inset-0 h-screen w-screen flex items-center justify-center"
    >
      <div class="flex flex-col gap-8">
        <button class="button w-52" @click="chooseLocale('de')">Deutsch</button>
        <button class="button w-52" @click="chooseLocale('en')">English</button>
      </div>
    </div>
  </Default>
</template>

<script setup lang="ts">
import { post } from "@/api/main"
import { Action } from "@/api/types"
import { InvalidLangIndexError } from "@/errors/error"

const router = useRouter()

const chooseLocale = async (index: string) => {
  type StationMap = {
    [key: string]: Action
  }

  const langMap: StationMap = {
    en: "lang_01",
    de: "lang_02",
  }

  try {
    if (Object.keys(langMap).includes(index)) {
      await post(langMap[index])
      router.push({ name: "StationsIndex", params: { locale: index } })
    } else {
      throw new InvalidLangIndexError(index)
    }
  } catch {
    throw new InvalidLangIndexError(index)
  }
}
</script>

<style lang="postcss" scoped></style>
