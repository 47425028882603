<template>
  <div
    v-if="mockApiBase"
    class="absolute h-9 p-2 left-0 right-0 bg-yellow-300 text-sm border-b"
  >
    Mock API base is set to <b>{{ mockApiBase }}</b> - The application doesn't
    send any requests to the installation. See
    <RouterLink class="underline font-bold" :to="{ name: 'Settings' }"
      >Settings</RouterLink
    >
    for more details.
  </div>
  <div
    class="p-16 flex flex-col h-screen select-none"
    :class="mockApiBase ? 'pt-16' : ''"
  >
    <div class="flex justify-between pb-4">
      <div><slot name="actions" /></div>
      <div class="h-12">
        <button v-if="showLocaleSwitcher" class="button" @click="switchLocale">
          {{ t("shared.change_language") }}
        </button>
      </div>
    </div>
    <div v-if="$slots.title" class="flex justify-between py-8">
      <h1><slot name="title" /></h1>
    </div>
    <div class="mt-4 pb-8 flex-grow">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n"

const { t, locale } = useI18n()
const route = useRoute()
const router = useRouter()

const mockApiBase = ref("")

onMounted(() => {
  const MOCK_API_BASE = localStorage?.getItem("MOCK_API_BASE")
  if (MOCK_API_BASE?.startsWith("http")) {
    mockApiBase.value = MOCK_API_BASE
  }
})

defineProps({
  showLocaleSwitcher: { type: Boolean, default: true },
})

const switchLocale = () => {
  router.push({ name: "Index" })
  // const newLocale = locale.value === "de" ? "en" : "de"
  // router.push({
  //   name: route.name as string,
  //   params: { ...route.params, locale: newLocale },
  // })
}
</script>

<style lang="postcss" scoped></style>
