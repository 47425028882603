<template>
  <Default :show-locale-switcher="false">
    <template #actions>
      <RouterLink class="button" :to="{ name: 'Index' }">
        {{ t("shared.back") }}
      </RouterLink>
    </template>
    <template #title>Settings</template>

    <div class="border px-4 py-2 rounded flex gap-x-4 mb-8">
      <div>
        <b>Mode:</b> <code>{{ mode }}</code>
      </div>
      <div>
        <b>Revision:</b> <code>{{ revision }}</code>
      </div>
    </div>

    <div class="flex flex-col gap-y-4">
      <form @submit.prevent="save">
        <div
          class="grid grid-cols-2 gap-x-4 items-center border px-4 py-2 rounded"
        >
          <label for="useMockApiBase">
            <div class="font-bold">Mock API endpoint</div>
            <div class="text-sm text-gray-500">
              Enter an URL to override the API endpoint. This is NOT enabled on
              production. Leave empty to use the default mock endpoint, which
              returns
              <code class="bg-gray-200 rounded p-1">{ "success": true }</code>
              for all requests.
            </div>
          </label>
          <input
            id="useMockApiBase"
            v-model="mockApiBase"
            name="useMockApiBase"
            type="text"
          />
        </div>
        <button class="button mt-8" type="submit">Save</button>
      </form>
    </div>
  </Default>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n"
import { ref } from "vue"

const { t } = useI18n()

const mockApiBase = ref("")

const mode = import.meta.env.MODE
const revision = import.meta.env.VITE_GITHUB_SHA

onMounted(() => {
  const MOCK_API_BASE = localStorage?.getItem("MOCK_API_BASE")
  if (MOCK_API_BASE?.startsWith("http")) {
    mockApiBase.value = MOCK_API_BASE
  }
})

const save = () => {
  if (mockApiBase.value.startsWith("http")) {
    localStorage?.setItem("MOCK_API_BASE", mockApiBase.value)
  } else {
    mockApiBase.value = ""
    localStorage?.setItem("MOCK_API_BASE", "")
  }

  window.location.reload()
}
</script>
